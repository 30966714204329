import { Injectable } from '@angular/core';

import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { INotification } from '@core/models/notification.model';
import { NotificationType } from '@core/models/notification-type';
import { CommunicationService } from '@core/services/communication.service';
import { IStatusChange } from './status-change.model';

@Injectable({
  providedIn: 'root',
})
export class StatusChangeService {
  constructor(protected communicationService: CommunicationService) {}

  statusChanged = (allowedTypes: NotificationType[]): Observable<IStatusChange> => {
    return this.communicationService.message$.pipe(
      filter((notification: INotification) => allowedTypes.includes(notification.type)),
      map(notification => {
        const { id, type, status } = notification;
        return { id, type, status };
      })
    );
  };
}
